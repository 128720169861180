import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import ScoreProgressBar from "../components/ScoreProgressBar";
import "../components/layout.scss";
import Layout from "../components/layout";
import Loading from '../components/Loading';
import { getReport } from "../api/findings";


const reportsText = {
  'expression': {
    "about": "Your child’s expression skills represent all the ways your child shows or tells you what they want and need. Expression skills include both verbal and nonverbal communication.",
    "skill_to_support": [
      `This list of skills is the first glimpse of how you can support {child} in advancing {his/her} communication skills. The skills to support include both skills that most of your child’s peers have mastered and skills that are advanced for your child’s age (meaning we wouldn’t expect your child to learn these skills until they are older).`,
      `Your Toddler Talk Less Plan will show you how you can teach {him/her} each of the skills listed below. Your Lesson Plan will also organize your lessons so you can work on the skills your child is most ready to learn`
    ]
  },
  'comprehension': {
    "about": "Language comprehension refers to all the things your child understands. Comprehension skills include the words your child can understand, questions they can answer, and directions they can follow",
    "skill_to_support": [
      `This list of skills is the first glimpse of how you can support {child} in advancing {his/her} communication skills. The skills to support include both skills that most of your child’s peers have mastered and skills that are advanced for your child’s age (meaning we wouldn’t expect your child to learn these skills until they are older).`,
      `Your Toddler Talk Less Plan will show you how you can teach {him/her} each of the skills listed below. Your Lesson Plan will also organize your lessons so you can work on the skills your child is most ready to learn`
    ]
  },
  'pronunciation': {
    "about": "Pronunciation skills refer to the specific sounds your child can make. As your child’s vocabulary grows, their pronunciation improves and it becomes easier to understand what they are saying.",
    "skill_to_support": [
      `This list of skills is the first glimpse of how you can support {child} in advancing {his/her} communication skills. The skills to support include both skills that most of your child’s peers have mastered and skills that are advanced for your child’s age (meaning we wouldn’t expect your child to learn these skills until they are older).`,
      `Your Toddler Talk Less Plan will show you how you can teach {him/her} each of the skills listed below. Your Lesson Plan will also organize your lessons so you can work on the skills your child is most ready to learn`
    ]
  },
  'readiness': {
    "about": "Readiness skills focus on how easily your child pays attention and goes with the flow. By supporting your child’s attention and reducing their frustration, you can help your child learn new communication skills in less time.",
    "skill_to_support": [
      `This list of skills is the first glimpse of how you can support {child} in advancing {his/her} communication skills. The skills to support include both skills that most of your child’s peers have mastered and skills that are advanced for your child’s age (meaning we wouldn’t expect your child to learn these skills until they are older).`,
      `Your Toddler Talk Less Plan will show you how you can teach {him/her} each of the skills listed below. Your Lesson Plan will also organize your lessons so you can work on the skills your child is most ready to learn`
    ]
  }
}

const isValidSkill = (skill = '') => {
  const validSkills = ['expression', 'comprehension', 'pronunciation', 'readiness'];
  if (validSkills.includes(skill)) return true;
  return false;
}

const colorCodes = {
  expression: "#ff6665",
  comprehension: "#19baff",
  pronunciation: "#abcd51",
  readiness: "#ffc001",
};

const GetStartedButton = styled(Button)`
  position: fixed;
  top: 20px;
  right: 20px;
`;

const ExtraPaddingBottom = styled.div`
  padding-bottom: 30px;
`;

const Reports = ({ location }) => {
  const [displaySkill, setDisplaySkill] = useState('');
  const [loading, setLoading] = useState(false);
  const [skillNotValid, setSkillNotValid] = useState(false);
  const [skillReport, setSkillReport] = useState({
    name: '',
    gender: '',
    onTrackSkills: [],
    skillsToSupport: [],
    score: { percentage: 0, total: 0, correct: 0 }
  });

  useEffect(async () => {
    const params = new URLSearchParams(location.search);
    const skillName = params.get("skill");
    setDisplaySkill(skillName);
    if (isValidSkill(skillName)) {
      setLoading(true);
      let data = await getReport(skillName);
      setSkillReport({
        name: data.name,
        gender: data.gender,
        onTrackSkills: [...data.onTrackSkills],
        skillsToSupport: [...data.skillsToSupport],
        score: { ...data.score }
      })
      setLoading(false);
    } else {
      setSkillNotValid(true);
    }
  }, []);

  function parseText(text) {
    let str1 = text.replaceAll('{child}', skillReport.name);
    let str2 = str1.replaceAll('{his/her}', skillReport.gender && skillReport.gender === 'Boy' ? 'his' : 'her');
    let str3 = str2.replaceAll('{him/her}', skillReport.gender && skillReport.gender === 'Boy' ? 'him' : 'her')
    return str3;
  }

  function renderSkillsToSupport() {
    return (
      <div className="container-center" style={{ width: '100%' }}>
        <ExtraPaddingBottom />
        <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
          <h4 className="custom-heading text-center mb-4">
            Skills To Support
          </h4>
          {
            reportsText[displaySkill]['skill_to_support'].map((text, index) => {
              return <p>{parseText(text)}</p>
            })
          }
          {
            skillReport.skillsToSupport.map((skill, index) => {
              return (
                <div key={index}
                  style={{
                    display: 'flex', alignItems: 'flex-start', lineHeight: "2", color: "#271344", fontWeight: "bold"
                  }}>
                  <img
                    src={require(`../images/bullet-${displaySkill}.svg`)}
                    style={{ marginRight: '16px', marginTop: '6px' }}
                    height='32'
                    width='32' />
                  <p>{skill}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  function renderOnTrackSkills() {
    return (
      <div className="container-center" style={{ width: '100%' }}>
        <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
          <h4 className="custom-heading text-center mb-4">
            On Track Skills
          </h4>
          {
            skillReport.onTrackSkills.map((skill, index) => {
              return (
                <div key={index} style={{
                  display: 'flex', alignItems: 'flex-start', lineHeight: "2", color: "#271344", fontWeight: "bold"
                }}>
                  <img
                    src={require('../images/bullet-circle-check.svg')}
                    style={{ marginRight: '16px', marginTop: '6px' }}
                    height='32'
                    width='32' />
                  <p>{skill}</p>
                </div>
              )
            })
          }
        </div>
        <ExtraPaddingBottom />
      </div>
    )
  }

  if (loading) {
    return <Loading></Loading>
  }

  return (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                data-id="9f5f77a"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="4522805"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-10 elementor-inner-column elementor-element elementor-element-367b21f"
                                        data-id="367b21f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                            data-id="9265126"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container animation-bottom">
                                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="107"
                                                    src="../images/image-home-1.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-80 elementor-inner-column elementor-element elementor-element-8869f2a"
                                        data-id="8869f2a"
                                        data-element_type="column"
                                      >
                                        {
                                          skillNotValid
                                            ? <div className="cms-heading-wrapper cms-heading-layout1 wow pulse text-center">
                                              <h3 className="custom-heading">
                                                <span>No report exists for requested skill '{displaySkill}'</span>
                                              </h3>
                                            </div>
                                            : <div className="elementor-widget-wrap elementor-element-populated">
                                              <div
                                                className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading mb-5"
                                                data-id="8832270"
                                                data-element_type="widget"
                                                data-widget_type="cms_heading.default"
                                              >
                                                <div className="container-center">
                                                  <div class="cms-heading-wrapper">
                                                    <h3
                                                      class="custom-heading primary-color"
                                                      style={{
                                                        fontFamily:
                                                          "Schoolbell,Sans-serif",
                                                        fontSize: "17px",
                                                        fontWeight: 600,
                                                        letterSpacing: "3.5px",
                                                      }}
                                                    >
                                                      <span>Findings Report</span>
                                                    </h3>
                                                  </div>
                                                  <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                    <h3 className="custom-heading">
                                                      <span style={{ textTransform: 'capitalize' }}>{displaySkill ? displaySkill : ''}: {skillReport.score.percentage}%</span>
                                                    </h3>
                                                  </div>{" "}
                                                </div>

                                                <ExtraPaddingBottom />

                                                <div className="container-center">
                                                  <ScoreProgressBar
                                                    data={displaySkill ? [
                                                      {
                                                        fillPercent: skillReport['score']['percentage'],
                                                        fillColor:
                                                          colorCodes[displaySkill],
                                                        consideringScoreOf:
                                                          "expression",
                                                      },
                                                    ] : []}
                                                  />
                                                  <p className="text-center">
                                                    % skills equal to or more
                                                    advanced than average peers
                                                  </p>

                                                  <p className="text-left">
                                                    {reportsText[displaySkill] ? reportsText[displaySkill]['about'] : ''}{" "}
                                                  </p>
                                                </div>
                                              </div>

                                              {
                                                skillReport.onTrackSkills.length
                                                  ? renderOnTrackSkills()
                                                  : null
                                              }

                                              {
                                                skillReport.skillsToSupport.length
                                                  ? renderSkillsToSupport()
                                                  : null
                                              }

                                              <div className='reports-btn-container'>
                                                <a
                                                  class="learn-more-btn reports-btn"
                                                  //href="/findings"
                                                  href="/toddler-talk-assessment-findings"
                                                >
                                                  <span class="btn-text">
                                                    Back to Findings{" "}
                                                  </span>
                                                </a>

                                                <a
                                                  class="lesson-plan-btn reports-btn"
                                                  // href="/pricing"
                                                  href="/toddler-talk-plan-tutorial"
                                                >
                                                  <span className="lesson-plan-btn-text">
                                                    Continue to your lesson
                                                    plan{" "}
                                                  </span>
                                                </a>
                                              </div>

                                            </div>
                                        }
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-10 elementor-inner-column elementor-element elementor-element-6be929f"
                                        data-id="6be929f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-97fbc33 cms-animation-bottom-to-top2 elementor-widget elementor-widget-cms_image_single"
                                            data-id="97fbc33"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container animation-top">
                                              <div className="cms-image-single cms-animation-left-to-right ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="102"
                                                    height="86"
                                                    src="../images/image-home-2.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Reports;
