import axios from "axios";
import { apiUrl } from "../constants/api";

export const getFindings = async () => {
  const { data } = await axios.get(`${apiUrl}/findings`, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  return data.data;
};

export const getReport = async (skill) => {
  const { data } = await axios.get(`${apiUrl}/findings/report?skill=${skill}`, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  return data.data;
};


