import React from 'react';
import styled from "styled-components";

function ScoreProgressBar({data}) {
  let components = {};
  data.forEach((item, index) => {
    let colorSplit = item.fillColor.split("'");
    components['ProgressFiller' + (index+1)] = styled.span`
      height: 30px;
      display: flex;
      background-color: ${colorSplit[0]};
      width: ${Number(item.fillPercent)}%;
    `
  })

  return(
    <div className='findings-progressbar-container'>
      <div className='findings-progressbar-multi'>
        {
          data.length && Object.keys(components).map(item => {
            let Filler = components[item];
            return <Filler  />
          })
        }
      </div>
      <div className='findings-progressbar-markings'>
        <p className='findings-progressbar-cell'>0</p>
        <p className='findings-progressbar-cell'>10</p>
        <p className='findings-progressbar-cell'>20</p>
        <p className='findings-progressbar-cell'>30</p>
        <p className='findings-progressbar-cell'>40</p>
        <p className='findings-progressbar-cell'>50</p>
        <p className='findings-progressbar-cell'>60</p>
        <p className='findings-progressbar-cell'>70</p>
        <p className='findings-progressbar-cell'>80</p>
        <p className='findings-progressbar-cell'>90</p>
        <p className='font-weight-bold'>100</p>
      </div>
    </div>
  )
}

export default ScoreProgressBar;